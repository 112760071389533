import { ChangeEvent, memo } from 'react';
import isEqual from 'react-fast-compare';
import Files from '../Files';
import ProjectActions from '../ProjectActions/ProjectActions';
import Caption from './Caption';
import Info from './Info';
import Notes from './Notes/Notes';
import StatusBlock from './StatusBlock';
import CloseButton from 'components/common/CloseButton/CloseButton';
// styles
import {
  CloseWrapper,
  HeadInfo,
  Heading,
  HeadingBlock,
  HeadingLeft,
  HeadingRight,
  HeadingTop,
} from './styles';
import { IProjectResponseDto } from 'types';

interface IHeaderProps {
  data?: IProjectResponseDto & {
    isUntracked?: boolean;
    workOrderNumber?: string;
  };
  openEdit?: (isClone: boolean) => void;
  setCloneCount: (amount: number) => void;
  deleteProject?: () => void;
  deleteProjectLoading?: boolean;
  markProjectAsReadyLoading?: boolean;
  notAvailableProject?: boolean;
  markAsReady: () => void;
  openPreview: (e: ChangeEvent) => void;
  closeModal: () => void;
  toggleParent: (visible: boolean) => void;
  isDrawer?: boolean;
  restoreProject: () => void;
  isRestoreProjectLoading?: boolean;
}

const Header = ({
  data,
  openEdit,
  setCloneCount,
  deleteProject,
  deleteProjectLoading,
  markProjectAsReadyLoading,
  markAsReady,
  notAvailableProject,
  closeModal,
  toggleParent,
  isDrawer,
  restoreProject,
  isRestoreProjectLoading,
}: IHeaderProps) => {
  const {
    notes,
    files,
    createdBy,
    createdAt,
    invoice,
    prefix,
    bookingNumber,
    customer,
    startDate,
    totalActualHours,
    invoiceStatus,
    dueDate,
    account,
    estimatedTimeOfArrival,
    workOrderNumber,
  } = data;
  const fullName = createdBy?.fullName || '';

  return (
    <Heading>
      <HeadingTop>
        <Caption
          invoice={invoice}
          prefix={prefix}
          customer={customer}
          account={account}
          workOrderNumber={workOrderNumber}
        />
        {isDrawer && (
          <CloseWrapper>
            <CloseButton onClick={() => toggleParent(false)} />
          </CloseWrapper>
        )}
      </HeadingTop>
      <HeadingBlock>
        <HeadingLeft>
          <HeadInfo>
            <ProjectActions
              data={data}
              openEdit={openEdit}
              setCloneCount={setCloneCount}
              notAvailableProject={notAvailableProject}
              deleteProjectLoading={deleteProjectLoading}
              closeModal={closeModal}
              deleteProject={deleteProject}
            />
          </HeadInfo>
          <Notes notes={notes} />
          <Files
            data={data}
            isDrawer={isDrawer}
            editFilesData={files}
            notAvailableProject={notAvailableProject}
          />
        </HeadingLeft>
        <HeadingRight>
          {!data.isUntracked && (
            <StatusBlock
              data={data}
              isRestoreProjectLoading={isRestoreProjectLoading}
              restoreProject={restoreProject}
              markProjectAsReadyLoading={markProjectAsReadyLoading}
              markAsReady={markAsReady}
              notAvailableProject={notAvailableProject}
            />
          )}
          <Info
            estimatedTimeOfArrival={estimatedTimeOfArrival}
            createdAt={createdAt}
            startDate={startDate}
            totalActualHours={totalActualHours}
            invoiceStatus={invoiceStatus}
            dueDate={dueDate}
            fullName={fullName}
            bookingNumber={bookingNumber}
          />
        </HeadingRight>
      </HeadingBlock>
    </Heading>
  );
};

export default memo(Header, isEqual);
