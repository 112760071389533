export const TABLE_HEADER_COLUMNS = [
  'Date',
  'Order #',
  'Sales Rep',
  'Client',
  'Company',
];

export enum SORT_TYPES {
  UP = 'createdAt',
  DOWN = '-createdAt',
}
