// components
import FileItem from './FileItem';
// icons
import { ArrowLeft } from 'lib/icons';
// styles
import {
  Back,
  FileList,
  Info,
  OwnerEmail,
  OwnerEmailTitle,
  OwnerEmailValue,
  SubTitle,
  Title,
} from './styles';
import { FileUploadResDto } from 'types';

interface IHeadingProps {
  goBack: () => void;
  files: FileUploadResDto[];
  ownerEmail: string;
}

const Heading = ({ goBack, files, ownerEmail }: IHeadingProps): JSX.Element => (
  <div>
    <Back onClick={goBack}>
      <ArrowLeft />
      Go back
    </Back>
    <Info>
      <div>
        <Title>Divide</Title>
        <SubTitle>Choose which item belongs to which container</SubTitle>
      </div>
      {ownerEmail && (
        <OwnerEmail>
          <OwnerEmailTitle>Owner email</OwnerEmailTitle>
          <OwnerEmailValue>{ownerEmail}</OwnerEmailValue>
        </OwnerEmail>
      )}
    </Info>
    <FileList>
      {files.map(file => (
        <FileItem key={file.filePath} file={file} />
      ))}
    </FileList>
  </div>
);

export default Heading;
